import { graphql, Link } from "gatsby"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import DefaultHero from '../components/default-hero'
import CopyRightBG from '../components/copyright-bg'

import "../css/single-who-we-serve.css"

import YoastSeoParts from '../fragments/seo';

class SingleWhoWeServe extends Component {

  render() {
    const serve = this.props.data.wpWhoWeServe;

    const { seo } = serve
    const {
      imageLeftCopy,
      imageLeftTitle,
      imageLeftImage,
      serviceList,
      otherServicesTitle,
      otherServicesCopy,
      otherServicesCta
    } = serve.cptWhoWeServe;

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <div className="single-who-we-serve-container">
          <DefaultHero title={serve.title} backgroundColor="#65869C" />
          <CopyRightBG
            image={imageLeftImage}
            title={imageLeftTitle}
            copy={imageLeftCopy}
          />

          {serviceList && (
            <div className="container ContainerRepeat">
              {serviceList.map(({ title, copy, cta }) => (
                <div key={title} className="grid smallGrid align-center gridRepeat">
                  <div className="col-sm-9 maincopy">
                    <h3>{title}</h3>
                    <p>{copy}</p>
                  </div>
                  <div className="col-sm-3">
                    { cta &&
                      <Link className="mainCTA" to={cta.url}>{cta.title}</Link>
                    }
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="container copyFullLeft">
            <div className="grid smallGrid">
              <div className="col-sm-12 maincopy">

              <h2>{otherServicesTitle}</h2>
                <hr />
                <div dangerouslySetInnerHTML={{ __html: otherServicesCopy }} />

                {otherServicesCta && <Link className="mainCTA" to={otherServicesCta.url}>{otherServicesCta.title}</Link>}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SingleWhoWeServe

export const query = graphql`
  query($id: String!) {
    wpWhoWeServe(id: { eq: $id }) {
      title
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      cptWhoWeServe {
        imageLeftCopy
        imageLeftTitle
        imageLeftImage {
          altText
          localFile {
            publicURL
          }
        }
        serviceList {
          copy
          title
          cta {
            target
            title
            url
          }
        }
        otherServicesCopy
        otherServicesTitle
        otherServicesCta {
          title
          url
        }
      }
    }
  }
`